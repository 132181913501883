.ct-left-nav {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 20%), 0 16px 16px -1px rgb(8 11 14 / 50%);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 250px;
  left: -270px;
  height: 100vh;
  background: white;
  z-index: 1000;
  transition: all 0.2s ease;
  overflow-y: scroll;
}

.ct-left-nav > .ct-left-nav-container {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  min-height: 504px;
  height: 100%;
}

.ct-left-nav.ct-open {
  left: 0;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > .ct-link-parent {
  padding: 0 10px;
  margin-bottom: 15px;
  width: 100%;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.473);
  flex-wrap: wrap;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-display
  > p {
  flex: 0 0 100%;
  color: grey;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 5px;
  line-height: 12px;
  margin-top: -5px;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-display
  > span.ct-title {
  font-size: 14px;
  color: grey;
  text-transform: uppercase;
  font-weight: lighter;
  width: 100%;
  display: block;
  padding: 5px;
  flex: 0 0 70%;
  max-width: 70%;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-display
  > span.ct-openner {
  cursor: pointer;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links {
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  display: none;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links.ct-active {
  display: flex;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > a,
.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > span {
  color: black;
  text-decoration: none;
  position: relative;
  padding: 5px 10px 5px 5px;
  font-weight: lighter;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > a
  > div,
.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > span
  > div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > a.ct-disabled,
.ct-left-nav
  > .ct-left-nav-container
  > .ct-links
  > .ct-link-parent
  > .ct-parent-links
  > span.ct-disabled {
  color: grey;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > a {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
  transition: all 0.2s ease;
}

.ct-left-nav > .ct-left-nav-container > .ct-links > a:hover {
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-bottom: 30px;
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom > .ct-social {
  display: flex;
  color: black;
  justify-content: center;
  border-top: 1px solid black;
  margin: 0 auto;
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom > .ct-social > a {
  font-size: 25px;
  margin: 10px;
}

.ct-left-nav > .ct-left-nav-container > .ct-bottom > .ct-tokens {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 15px;
  text-align: center;
  color: grey;
}

@media screen and (min-width: 1600px) {
  .ct-left-nav {
    left: 0;
  }
}
