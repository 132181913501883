#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.ct-bidding-page > .ct-some-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: 25px;
  padding-top: 25px;
  width: 100%;
}

.ct-bidding-page > .ct-some-info > div.ct-text {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
}

.ct-bidding-page > .ct-some-info > div.ct-text > h4 {
  font-size: 25px;
}

.ct-bidding-page > .ct-some-info > div.ct-text > p {
  font-size: 12px;
}

.ct-bidding-page > .ct-some-info > div.ct-image {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-bidding-page > .ct-some-info > div.ct-image > img {
  width: 100%;
  height: 100%;
}

.ct-bidding-page .ct-tokenomics {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.ct-bidding-page .ct-tokenomics > .ct-data-containers {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 25px;
}

.ct-bidding-page .ct-tokenomics > .ct-data-containers > div {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  flex-direction: column;
}

.ct-bidding-page .ct-tokenomics > .ct-data-containers > div > span {
  cursor: pointer;
}
