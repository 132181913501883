.ctMintedAnimations {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: -100px;
  width: 120%;
  height: 120%;
  background: black;
  z-index: -10;
}

.ctList {
  display: flex;
  flex-wrap: wrap;
}

.ctList > div {
  overflow: hidden;
  flex: 0 0 25%;
  width: 25%;
}

.ctList > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ctMintedShadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.771);
  z-index: -5;
}

@media screen and (min-width: 700px) {
    .ctList > div {
        overflow: hidden;
        flex: 0 0 20%;
        width: 20%;
      }
}


@media screen and (min-width: 1000px) {
    .ctList > div {
        overflow: hidden;
        flex: 0 0 10%;
        width: 10%;
      }
}
